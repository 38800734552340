import { ready } from "./functions";
ready(function (vars) {
    //Bind lightbox opening
    var lightBox = document.querySelectorAll('.open-lightbox');
    lightBox && lightBox.forEach(function (lbButton, i) {
        lbButton.addEventListener('click', function (e) {
            e.preventDefault();
            window.openLightbox('iframe', lbButton.getAttribute('data-lbcontent'));
        });
    });
    document.addEventListener('click', function (e) {
        var target = e.target;
        if (target && target.classList.contains('open-lightbox')) {
            e.preventDefault();
            window.openLightbox('iframe', target.getAttribute('data-lbcontent'));
        }
    });
});
window.openLightbox = function (lightboxContentType, lightboxContent) {
    var body = document.querySelector('body');
    var lightboxContentElement;
    //Get lightbox object (if exists) and clear
    var existingLightbox = document.getElementById('lightbox');
    existingLightbox && existingLightbox.remove();
    //IFRAME content Type
    if (lightboxContentType == 'iframe') {
        //Prepare the content
        lightboxContentElement = document.createElement('iframe');
        lightboxContentElement.setAttribute('src', lightboxContent);
    }
    //Create basic lightbox
    var newLightbox = document.createElement('div');
    newLightbox.id = 'lightbox';
    newLightbox.classList.add('lightbox', 'open');
    //Append the closing button
    var closeButton = document.createElement('button');
    closeButton.innerText = 'Sluit';
    closeButton.classList.add('lightbox-close');
    closeButton.setAttribute('title', 'Sluiten');
    closeButton.setAttribute('onclick', 'window.closeLightbox()');
    //Fill the lightbox
    var innerContent = document.createElement('div');
    innerContent.classList.add('lightbox-inner');
    innerContent.append(lightboxContentElement);
    newLightbox.append(closeButton);
    newLightbox.append(innerContent);
    //Append the lightbox to the body
    body.appendChild(newLightbox);
    body.classList.add('lightbox-open', 'no-scroll');
    document.addEventListener('keyup', function (e) {
        if (e.key === 'Escape' || e.key === 'Esc') {
            window.closeLightbox();
        }
    });
};
window.closeLightbox = function closeLightbox() {
    var body = document.querySelector('body');
    body.classList.remove('lightbox-open', 'no-scroll');
    var existingLightbox = document.getElementById('lightbox');
    existingLightbox && existingLightbox.remove();
};
