import { ready, slideUp, slideDown } from "./functions";
ready(function (vars) {
    var faqContainer = document.querySelector('aside.faq');
    if (faqContainer) {
        var faqToggles_1 = document.querySelectorAll('.faq-toggle');
        var faqQuestions_1 = faqContainer.querySelectorAll('.faq-question');
        // Close FAQ when clicked outside
        document.addEventListener('click', function (e) {
            var targetElement = e.target;
            var clickedFaq = false;
            faqToggles_1 === null || faqToggles_1 === void 0 ? void 0 : faqToggles_1.forEach(function (faqToggle) {
                if (faqToggle.contains(targetElement) || faqContainer.contains(targetElement)) {
                    clickedFaq = true;
                }
            });
            if (!clickedFaq) {
                faqContainer.classList.remove('open');
                faqToggles_1 === null || faqToggles_1 === void 0 ? void 0 : faqToggles_1.forEach(function (faqToggle) {
                    faqToggle.classList.remove('active');
                });
            }
        });
        // Open/close FAQ
        faqToggles_1 === null || faqToggles_1 === void 0 ? void 0 : faqToggles_1.forEach(function (faqToggle) {
            faqToggle === null || faqToggle === void 0 ? void 0 : faqToggle.addEventListener('click', function (e) {
                var targetElement = e.target;
                if (targetElement.nodeName.toLowerCase() === 'a') {
                    return;
                }
                else {
                    e.preventDefault();
                }
                faqToggles_1 === null || faqToggles_1 === void 0 ? void 0 : faqToggles_1.forEach(function (faqToggle) {
                    faqToggle.classList.toggle('active');
                });
                if (faqContainer.classList.contains('open')) {
                    // Close FAQ
                    faqContainer.classList.remove('open');
                }
                else {
                    // Open FAQ
                    faqContainer.classList.add('open');
                }
            });
        });
        // Open/close answers
        faqQuestions_1 === null || faqQuestions_1 === void 0 ? void 0 : faqQuestions_1.forEach(function (question) {
            question.addEventListener('click', function (e) {
                var targetElement = e.target;
                var targetParentElement = targetElement.parentNode;
                if (!targetElement.classList.contains('title') && !targetParentElement.classList.contains('title')) {
                    return;
                }
                // Get other questions
                var otherQuestions = Array.from(faqQuestions_1).filter(function (faqQuestion) {
                    return e.currentTarget != faqQuestion;
                });
                // Close other questions
                otherQuestions.forEach(function (otherQuestion) {
                    otherQuestion.classList.remove('active');
                    slideUp(otherQuestion.querySelector('.content'));
                });
                // Open/close clicked
                if (question.classList.contains('active')) {
                    question.classList.remove('active');
                    slideUp(question.querySelector('.content'));
                }
                else {
                    question.classList.add('active');
                    slideDown(question.querySelector('.content'));
                }
            });
        });
    }
});
