import { doRequest, slideDown, slideUp } from "./functions";
import { createCKEditors, resetCKEditorsInParent } from "./editor";
var cmsPagesForm = document.querySelector('.cms-pages-form'), cmsPagesImageFieldsContainer = cmsPagesForm === null || cmsPagesForm === void 0 ? void 0 : cmsPagesForm.querySelector('.cms-page-image-fields'), cmsPagesMainFieldsContainer = cmsPagesForm === null || cmsPagesForm === void 0 ? void 0 : cmsPagesForm.querySelectorAll('.cms-page-dynamic-main-fields'), cmsPagesCustomMainFieldsContainer = cmsPagesForm === null || cmsPagesForm === void 0 ? void 0 : cmsPagesForm.querySelectorAll('.custom-fields'), cmsPagesCustomSidebarFieldsContainer = cmsPagesForm === null || cmsPagesForm === void 0 ? void 0 : cmsPagesForm.querySelectorAll('.cms-page-custom-sidebar-fields'), cpPreviewButton = cmsPagesForm === null || cmsPagesForm === void 0 ? void 0 : cmsPagesForm.querySelector('.cp-preview-page');
var cmsLayerForm = document.querySelector('.cms-layers');
var templateSelector = document.getElementById('page_template');
//-----------------------------------------------------
// Dynamic Template Selector
//-----------------------------------------------------
var updateCMSPagesFields = function () {
    var _a, _b;
    if (templateSelector && templateSelector.value != '') {
        var selectedTemplate = templateSelector.value, existingPageID = (((_a = cmsPagesForm.querySelector('#existingPageID')) === null || _a === void 0 ? void 0 : _a.value) != undefined ? cmsPagesForm.querySelector('#existingPageID').value : null), editMode = (_b = cmsPagesForm.querySelector('#editMode')) === null || _b === void 0 ? void 0 : _b.value;
        var fd = new FormData;
        fd.append('selectedTemplate', selectedTemplate);
        fd.append('existingPageID', existingPageID !== null && existingPageID !== void 0 ? existingPageID : null);
        fd.append('editMode', editMode);
        //Request the template fields
        doRequest(window.cmsurl + '/structure/handlers/page_dynamic_field_handler.php', fd).then(function (templateFields) {
            //Fill the image images
            cmsPagesImageFieldsContainer.innerHTML = templateFields.imageFields;
            //Fill the main fields
            cmsPagesMainFieldsContainer === null || cmsPagesMainFieldsContainer === void 0 ? void 0 : cmsPagesMainFieldsContainer.forEach(function (cmsPagesMainFieldLangContainer) {
                var lang = cmsPagesMainFieldLangContainer.getAttribute('data-lang');
                cmsPagesMainFieldLangContainer.innerHTML = templateFields.mainFields[lang];
            });
            //Fill the custom fields in main
            if (templateFields.customMainFields) {
                cmsPagesCustomMainFieldsContainer === null || cmsPagesCustomMainFieldsContainer === void 0 ? void 0 : cmsPagesCustomMainFieldsContainer.forEach(function (cmsPagesCustomFieldsLangContainer) {
                    var lang = cmsPagesCustomFieldsLangContainer.getAttribute('data-lang');
                    cmsPagesCustomFieldsLangContainer.innerHTML = templateFields.customMainFields[lang];
                });
            }
            //Fill the custom fields in sidebar
            if (templateFields.customSidebarFields) {
                cmsPagesCustomSidebarFieldsContainer === null || cmsPagesCustomSidebarFieldsContainer === void 0 ? void 0 : cmsPagesCustomSidebarFieldsContainer.forEach(function (cmsPagesCustomFieldsLangContainer) {
                    var lang = cmsPagesCustomFieldsLangContainer.getAttribute('data-lang');
                    cmsPagesCustomFieldsLangContainer.innerHTML = templateFields.customSidebarFields[lang];
                });
            }
            //Hide or show layers
            if (templateFields.layersVisible) {
                cmsLayerForm === null || cmsLayerForm === void 0 ? void 0 : cmsLayerForm.classList.remove('cms-layers-hidden');
            }
            else {
                cmsLayerForm === null || cmsLayerForm === void 0 ? void 0 : cmsLayerForm.classList.add('cms-layers-hidden');
            }
            if (templateFields.previewVisible) {
                if (cpPreviewButton) {
                    slideDown(cpPreviewButton);
                }
            }
            else {
                if (cpPreviewButton) {
                    slideUp(cpPreviewButton);
                }
            }
            resetCKEditorsInParent('page');
            resetCKEditorsInParent('module');
            createCKEditors(window.cmsurl, window.site_url);
        });
    }
};
//-----------------------------------------------------
// Check if we are on the page_modify template
//-----------------------------------------------------
if (cmsPagesForm) {
    //Find the loaded template
    updateCMSPagesFields();
    templateSelector.addEventListener('change', function (e) {
        e.preventDefault();
        updateCMSPagesFields();
    });
}
//-----------------------------------------------------
// Handle Chapter Filter
//-----------------------------------------------------
var pageFilterFarm = document.querySelector('form.page-filters select.page-filter'), pagesTable = document.querySelector('.cms-table[data-dbtable="cms_pages"]'), pagesTableRows = pagesTable === null || pagesTable === void 0 ? void 0 : pagesTable.querySelectorAll('tbody tr');
pageFilterFarm === null || pageFilterFarm === void 0 ? void 0 : pageFilterFarm.addEventListener('change', function () {
    var selectedChapter = pageFilterFarm.value;
    //Clear existing matches
    pagesTableRows.forEach(function (pageTableRow) {
        pageTableRow.classList.remove('matches-filter');
    });
    //We need to filter
    if (selectedChapter != 'all') {
        //Hide all the other chapters
        pagesTable.classList.add('filtering');
        pagesTableRows.forEach(function (pageTableRow) {
            console.log(pageTableRow.getAttribute('data-chapter'));
            if (pageTableRow.getAttribute('data-chapter') == selectedChapter) {
                pageTableRow.classList.add('matches-filter');
            }
        });
    }
    //We are clearing the filter
    else {
        //Hide all the other chapters
        pagesTable.classList.remove('filtering');
    }
});
