import { ready, doRequest, slideUp, slideDown } from "./functions";
ready(function (vars) {
    //Watch for deletions
    var cmstable = document.querySelector('.cms-table');
    if (cmstable) {
        var handler_1 = cmstable.getAttribute('data-handler'), table_1 = cmstable.getAttribute('data-dbtable');
        //Delete row
        var deleteItems = document.querySelectorAll('.delete-item');
        deleteItems === null || deleteItems === void 0 ? void 0 : deleteItems.forEach(function (button) {
            button.addEventListener('click', function () {
                var row = button.parentElement.parentElement, name = row.getAttribute('data-name'), id = row.getAttribute('data-rowid'), fd = new FormData();
                var noPrompt = button.getAttribute('data-noprompt'), userConfirmed = false;
                if (noPrompt && noPrompt == 'true') {
                    userConfirmed = true;
                }
                else {
                    userConfirmed = confirm('Weet u zeker dat u de ' + name + ' wilt verwijderen?');
                }
                if (userConfirmed === true) {
                    fd.append('request', 'delete');
                    fd.append('db_table', table_1);
                    fd.append('id', id);
                    doRequest(handler_1, fd)
                        .then(function (data) {
                        if (data.status === true) {
                            row.remove();
                            //See new count
                            var newCount = cmstable.querySelectorAll('tbody tr').length;
                            window.location.href = window.location.href;
                        }
                    });
                }
            });
        });
        //Duplicate row
        var duplicateRows = document.querySelectorAll('.copy-item');
        duplicateRows === null || duplicateRows === void 0 ? void 0 : duplicateRows.forEach(function (button) {
            button.addEventListener('click', function () {
                var row = button.parentElement.parentElement, name = row.getAttribute('data-name'), id = row.getAttribute('data-rowid'), fd = new FormData();
                // if (confirm('Weet u zeker dat u het item met de naam ' + name + ' wilt dupliceren?')) {
                fd.append('request', 'duplicate');
                fd.append('db_table', table_1);
                fd.append('id', id);
                doRequest(handler_1, fd)
                    .then(function (data) {
                    if (data.status === true) {
                        window.location.reload();
                    }
                });
                // }
            });
        });
        //Switch row visibility
        var switchVisibility = document.querySelectorAll('.switch-visibility');
        switchVisibility === null || switchVisibility === void 0 ? void 0 : switchVisibility.forEach(function (button) {
            button.addEventListener('click', function () {
                var row = button.parentElement.parentElement, name = row.getAttribute('data-name'), newVis, id = row.getAttribute('data-rowid'), fd = new FormData();
                var totalActiveField = document.querySelector('span.total-active'), totalActiveCount = parseInt(totalActiveField === null || totalActiveField === void 0 ? void 0 : totalActiveField.textContent);
                if (button.classList.contains('visible')) {
                    newVis = 0;
                    button.classList.remove('visible');
                    totalActiveCount--;
                }
                else {
                    newVis = 1;
                    button.classList.add('visible');
                    totalActiveCount++;
                }
                if (totalActiveField) {
                    totalActiveField.textContent = totalActiveCount.toString();
                }
                fd.append('request', 'switch-visibility');
                fd.append('db_table', table_1);
                fd.append('id', id);
                fd.append('new-visibility', newVis.toString());
                doRequest(handler_1, fd);
            });
        });
        //Row selections
        var rowCheckboxes_1 = document.querySelectorAll('.select-row'), lastChecked_1;
        rowCheckboxes_1 === null || rowCheckboxes_1 === void 0 ? void 0 : rowCheckboxes_1.forEach(function (rowCheckbox) {
            // Track changes for delete button
            rowCheckbox.addEventListener('change', function (e) {
                //See total checked count
                var checkedCount = document.querySelectorAll('.select-row:checked').length;
                //Remove/add classes from button
                if (checkedCount > 0) {
                    document.querySelectorAll('.selected-action').forEach(function (actionbtn) {
                        slideDown(actionbtn, 'flex');
                    });
                }
                else {
                    document.querySelectorAll('.selected-action').forEach(function (actionbtn) {
                        slideUp(actionbtn);
                    });
                }
            });
            var inBetween = false, shiftKeyDown = false;
            // Check shift key
            document.addEventListener('keydown', function (e) {
                shiftKeyDown = e.shiftKey;
            });
            document.addEventListener('keyup', function (e) {
                shiftKeyDown = e.shiftKey;
            });
            // Track checkbox clicks
            rowCheckbox.addEventListener('click', function (e) {
                if (shiftKeyDown && rowCheckbox.checked) {
                    rowCheckboxes_1.forEach(function (rowCheckbox) {
                        // Flip the flag variable to true when we check first item then to false when we check the last item
                        if (rowCheckbox === e.target || rowCheckbox.closest('tr').getAttribute('data-rowid') === lastChecked_1) {
                            inBetween = !inBetween;
                        }
                        // Check all the checkboxes between
                        if (inBetween) {
                            rowCheckbox.checked = true;
                        }
                    });
                }
                lastChecked_1 = e.target.closest('tr').getAttribute('data-rowid');
            });
        });
        //Remove multiple rows
        var deleteMultiple = document.querySelector('.delete-selected');
        deleteMultiple === null || deleteMultiple === void 0 ? void 0 : deleteMultiple.addEventListener('click', function (e) {
            e.preventDefault();
            //We need to fetch all the selected rows
            var selectedRows = document.querySelectorAll('.select-row:checked'), totalRowsSelected = selectedRows.length;
            if (confirm('Weet u zeker dat u ' + totalRowsSelected.toString() + ' rij(en) wilt verwijderen?')) {
                selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.forEach(function (rowToDelete) {
                    //Call the rows deletion function
                    var tableRow = rowToDelete.parentElement.parentElement, singleDeleteButton = tableRow.querySelector('.delete-item');
                    singleDeleteButton.setAttribute('data-noprompt', 'true');
                    singleDeleteButton.click();
                });
            }
        });
        //Expandable table
        if (cmstable.classList.contains('expandable-table')) {
            //Find all expandable lists
            var expandableLists = cmstable.querySelectorAll('button.expand-list');
            expandableLists.forEach(function (expandableList) {
                expandableList.addEventListener('click', function (e) {
                    var listToExpand = expandableList.parentElement;
                    if (listToExpand) {
                        if (listToExpand.classList.contains('expanded')) {
                            listToExpand.classList.remove('expanded');
                            expandableList.innerHTML = 'Toon alles';
                        }
                        else {
                            listToExpand.classList.add('expanded');
                            expandableList.innerHTML = 'Verberg';
                        }
                    }
                });
            });
        }
    }
});
