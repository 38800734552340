import tinymce from 'tinymce';
import { ready, doRequest, slideUp, slideDown } from './functions';
ready(function (vars) {
    var form = document.querySelector('form:not(.layer-form):not(.no-default-submit)');
    form && form.addEventListener('submit', function (e) {
        e.preventDefault();
        var form = e.target;
        //Find the form action
        var formAction = form.getAttribute('action'), ckeditors = form.querySelectorAll('.load-ckeditor'), checkboxes = form.querySelectorAll('input[type=checkbox]:not(:checked)'), requiredInputs = form.querySelectorAll('[required]'), requiredFields = [], fd = new FormData(form), submitButton = form.querySelector('[type="submit"]'), messages = form === null || form === void 0 ? void 0 : form.querySelectorAll('.message');
        submitButton === null || submitButton === void 0 ? void 0 : submitButton.classList.add('loading');
        tinymce.get().forEach(function (editor) {
            fd.set(editor.getElement().getAttribute('name'), editor.getContent());
        });
        //Loop required inputs and append them to the formdata in a separate field
        requiredInputs.forEach(function (reqField) {
            if (!requiredFields.includes(reqField.getAttribute('name'))) {
                requiredFields.push(reqField.getAttribute('name'));
            }
        });
        fd.append('required-fields', requiredFields.join('|'));
        //Loop (unchecked) checkboxes and append a value of 0 for the query
        checkboxes.forEach(function (cb) {
            //See if name contains array brackets
            var cbName = cb.getAttribute('name');
            //Ignore checkbox arrays
            if (!cbName.includes('[]')) {
                fd.append(cbName, '0');
            }
        });
        doRequest(formAction, fd).then(function (data) {
            if (data.status === true) {
                messages === null || messages === void 0 ? void 0 : messages.forEach(function (message) {
                    message.classList.remove('error');
                    message.classList.add('success');
                });
                //Verify if post-action is set
                var postSubmitAction = form.querySelector('input[name="post-submit"]');
                if (postSubmitAction) {
                    window.location.href = postSubmitAction.value;
                }
            }
            else {
                //Display the message
                messages === null || messages === void 0 ? void 0 : messages.forEach(function (message) {
                    message.classList.remove('success');
                    message.classList.add('error');
                });
            }
            messages === null || messages === void 0 ? void 0 : messages.forEach(function (message) {
                var backButton = document.querySelector('.back-button');
                message.innerHTML = data.message;
                if (backButton && data.status != false) {
                    var backButtonCopy = backButton.cloneNode(true);
                    message.appendChild(backButtonCopy);
                }
                slideDown(message);
                if (data.status != false) {
                    setTimeout(function () {
                        slideUp(message);
                    }, 2000);
                }
            });
            setTimeout(function () {
                submitButton === null || submitButton === void 0 ? void 0 : submitButton.classList.remove('loading');
            }, 250);
        });
    });
});
