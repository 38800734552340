import { slideDown, slideUp } from "./functions";
var versionRadioBtns = document.querySelectorAll('input[name="cp-version-type-selector"]'), mainFields = document.querySelector('.main-fields'), chapterSelector = mainFields === null || mainFields === void 0 ? void 0 : mainFields.querySelector('.chapter-field');
versionRadioBtns.forEach(function (versionRadioBtn) {
    versionRadioBtn.addEventListener('change', function (e) {
        e.preventDefault();
        var selectedOption = parseInt(versionRadioBtn.value);
        //Show the fields (if an option was selected)
        if (mainFields.classList.contains('hidden')) {
            slideDown(mainFields).then(function () {
                mainFields.classList.remove('hidden');
            });
        }
        if (selectedOption === 3 && chapterSelector.classList.contains('hidden')) {
            slideDown(chapterSelector).then(function () {
                chapterSelector.classList.remove('hidden');
            });
        }
        else {
            slideUp(chapterSelector).then(function () {
                chapterSelector.classList.add('hidden');
                chapterSelector.querySelector('select').value = '';
            });
        }
    });
});
