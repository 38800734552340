/*
    Ratio CMS Entry
*/
function requireAll (r) {
    r.keys().forEach(r);
}

//Require SASS
require('../sass/style.scss');

//Require all TypeScript
requireAll(require.context('../js/', true, /\.ts$/));

//Require Layer TypeScript
requireAll(require.context('../layers/js/', true, /\.ts$/));