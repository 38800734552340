import { ready } from "./functions";
import tinymce from 'tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import '../sass/tinymce/skin.scss';
import '../sass/tinymce/skin.mobile.scss';
import '../sass/tinymce/skin_dark.scss';
import '../sass/tinymce/skin_dark.mobile.scss';
import 'tinymce/models/dom/model';
import './editor_nl';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/image';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/media';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/fullscreen';
export var resetCKEditorsInParent = function (elementClass) {
    tinymce.get().forEach(function (editor) {
        var classList = editor.getElement().getAttribute('name');
        if (classList.includes(elementClass)) {
            editor.getElement().innerHTML = editor.getContent();
            editor.getElement().classList.remove('ckeditor-opened');
            editor.remove();
        }
    });
};
export var createCKEditors = function (cmsurl, siteurl) {
    if (cmsurl === void 0) { cmsurl = window.cmsurl; }
    if (siteurl === void 0) { siteurl = window.site_url; }
    var desiredEditors = document.querySelectorAll('.load-ckeditor');
    desiredEditors === null || desiredEditors === void 0 ? void 0 : desiredEditors.forEach(function (editor) {
        // Disable sticky toolbar for editors in layers (buggy)
        var stickyToolbar = !editor.classList.contains('layer-text') ? true : false;
        // If visible and not already loaded
        if (editor.offsetParent !== null && !editor.classList.contains('ckeditor-opened')) {
            //Load TinyMCE
            tinymce.init({
                language: 'nl',
                target: editor,
                min_height: 320,
                height: 320,
                skin: false,
                relative_urls: false,
                document_base_url: siteurl,
                //content_css: siteurl + '/dist/bundle.css',
                content_css: siteurl + '/dist/editorStyling.css',
                plugins: [
                    'advlist', 'lists', 'image', 'media', 'table', 'code', 'link', 'autolink', 'fullscreen' //, 'autoresize'
                ],
                external_plugins: { 'cp-internal-links': cmsurl + '/editor_plugins/InternalLinks/index.js' },
                menubar: 'edit insert format',
                menu: {
                    edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall' },
                    insert: { title: 'Insert', items: 'image media | inserttable link | hr' },
                    format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks align | removeformat' }
                },
                link_class_list: [
                    { title: 'Geen', value: '' },
                    { title: 'Knop', value: 'button' }
                ],
                toolbar: 'undo redo | blocks align | bold italic | bullist numlist | image media | table link unlink | cp-internal-link-button | code fullscreen',
                toolbar_sticky: stickyToolbar,
                toolbar_sticky_offset: 85,
                // autoresize_overflow_padding: 20,
                file_picker_callback: function (cb, value, meta) {
                    //We need to open FM Popup
                    var popupXPos = window.outerWidth * .5 - 640;
                    var popupYPos = window.outerHeight * .5 - 360;
                    window.tinycallback = cb;
                    window.open(cmsurl + '/core/dependencies/filemanager/filemanager.php?URL=' + cmsurl + '&CKEditor', null, 'popup=yes,width=1280,height=720,screenX=' + popupXPos + ',screenY=' + popupYPos);
                },
            });
            editor.classList.add('ckeditor-opened');
        }
    });
};
//Loop CKEDITORS
ready(function (vars) {
    createCKEditors(window.cmsurl, window.site_url);
});
