import { doRequest, slideDown, slideUp } from "./functions";
//-----------------------------------------------------
// Changed Pages
//-----------------------------------------------------
//@ts-ignore
import HtmlDiff from 'htmldiff-js';
var changedPageContainers = document.querySelectorAll('.changed-page-container');
changedPageContainers === null || changedPageContainers === void 0 ? void 0 : changedPageContainers.forEach(function (changedPageContainer) {
    var changedPageTitle = changedPageContainer.querySelector('.changed-page-title'), changedPageContentOld = changedPageContainer.querySelector('.changed-page-old'), changedPageContentNew = changedPageContainer.querySelector('.changed-page-new'), changedPageContentDiff = changedPageContainer.querySelector('.changed-page-content');
    //Check if there is an actual difference
    if (changedPageContentDiff && changedPageContentNew) {
        changedPageContentDiff.innerHTML = HtmlDiff.execute(changedPageContentOld.innerHTML, changedPageContentNew.innerHTML);
        changedPageContentOld.remove();
        changedPageContentNew.remove();
    }
    //Remove empty ins / del elements
    var allChanges = changedPageContentDiff.querySelectorAll('ins, del');
    allChanges.forEach(function (change) {
        if (change.textContent.length == 0 || change.textContent == "\n\n" || change.textContent == "\n") {
            change.remove();
        }
    });
    //Add click listener
    changedPageTitle === null || changedPageTitle === void 0 ? void 0 : changedPageTitle.addEventListener('click', function () {
        if (changedPageContainer.classList.contains('opened')) {
            changedPageContainer.classList.remove('opened');
            slideUp(changedPageContentDiff).then(function () {
            });
        }
        else {
            changedPageContainer.classList.add('opened');
            slideDown(changedPageContentDiff, 'flex').then(function () {
            });
        }
    });
    //Add event listener for rollback
    var changedPageRollbackBtn = changedPageContainer.querySelector('.changed-page-rollback');
    changedPageRollbackBtn.addEventListener('click', function (e) {
        e.preventDefault();
        if (confirm('Weet u zeker dat u de wijzigingen aan deze pagina wilt terugdraaien? Dit kan niet ongedaan gemaakt worden.')) {
            var pageToRollback = changedPageRollbackBtn.parentElement.parentElement.getAttribute('data-page');
            var fd = new FormData();
            fd.append('pageToRollback', pageToRollback);
            doRequest(window.cmsurl + '/calamiteitenplannen/handlers/page_rollback_handler.php', fd).then(function (data) {
                if (data.status) {
                    window.location.reload();
                }
                else {
                    alert('Er is iets misgegaan met het terugdraaien van deze pagina. Probeer het later opnieuw.');
                }
            });
        }
    });
});
//-----------------------------------------------------
// Link table
//-----------------------------------------------------
var cpLinkTable = document.querySelector('.cp-link-table'), cpLinkTablePlaceholder = document.querySelector('.cp-link-table-placeholder');
if (cpLinkTable) {
    //Get all links in the table
    var totalLinksResolved_1 = 0, cpLinkRows_1 = cpLinkTable.querySelectorAll('.cp-link-row');
    if (cpLinkRows_1.length > 0) {
        cpLinkRows_1.forEach(function (cpLinkRow) {
            var _a;
            //Prepare the verify request
            var cpLink = (_a = cpLinkRow.querySelector('.cp-link-col')) === null || _a === void 0 ? void 0 : _a.textContent, cpLinkErrorCol = cpLinkRow.querySelector('.cp-link-status-col');
            //Create the request
            var fd = new FormData();
            fd.append('cpLinkToCheck', cpLink);
            //Do the request
            doRequest(window.cmsurl + '/calamiteitenplannen/handlers/brokenlink_handler.php', fd).then(function (data) {
                //Remove the row if the link is valid
                if (data.linkValid) {
                    cpLinkRow.remove();
                }
                //Update the error message
                else {
                    cpLinkErrorCol.innerHTML = data.pageBrokenReason;
                }
                totalLinksResolved_1++;
                if (totalLinksResolved_1 === cpLinkRows_1.length) {
                    slideUp(cpLinkTablePlaceholder).then(function () {
                        cpLinkTablePlaceholder.remove();
                        slideDown(cpLinkTable.parentElement);
                    });
                }
            });
        });
    }
    else {
        slideUp(cpLinkTablePlaceholder).then(function () {
            cpLinkTablePlaceholder.remove();
            slideDown(cpLinkTable.parentElement);
            cpLinkTable.parentElement.innerHTML = '<p>Er zijn geen links in het plan gevonden.</p>';
        });
    }
}
//-----------------------------------------------------
// Switch feedback processed
//-----------------------------------------------------
var showProcessedFeedbackCheckbox = document.getElementById('show_processed_feedback');
showProcessedFeedbackCheckbox === null || showProcessedFeedbackCheckbox === void 0 ? void 0 : showProcessedFeedbackCheckbox.addEventListener('change', function () {
    var cpFeedbackTable = document.querySelector('.cms-table.cp-feedback-table');
    if (showProcessedFeedbackCheckbox.checked) {
        cpFeedbackTable.classList.add('show-processed-feedback');
    }
    else {
        cpFeedbackTable.classList.remove('show-processed-feedback');
    }
});
//-----------------------------------------------------
// Extra event listener to change classes for switch feedback
//-----------------------------------------------------
var feedbackProcessedSwitches = document.querySelectorAll('.cp-feedback-table .switch-visibility');
feedbackProcessedSwitches.forEach(function (feedbackProcessedSwitch) {
    feedbackProcessedSwitch.addEventListener('click', function () {
        //The feedback is currently processed, mark it as unprocessed
        if (feedbackProcessedSwitch.classList.contains('visible')) {
            feedbackProcessedSwitch.parentElement.parentElement.classList.remove('feedback-processed');
        }
        //The feedback is currently unprocessed, mark it as processed
        else {
            feedbackProcessedSwitch.parentElement.parentElement.classList.add('feedback-processed');
        }
    });
});
