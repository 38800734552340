import { doRequest, ready } from './functions';
var updateURLTrigger = document.querySelector('button.trigger-url-replace');
var generatePasswordButton = document.querySelector('button.generate-password');
var showPasswordButton = document.querySelector('button.show-password');
var passwordField = document.querySelector('input#cms_password');
ready(function () {
    // Update URL's in database
    updateURLTrigger === null || updateURLTrigger === void 0 ? void 0 : updateURLTrigger.addEventListener('click', function (e) {
        e.preventDefault();
        var findValue = prompt('Vul URL in om te vervangen'), replaceWithValue = prompt('Vul de nieuwe URL in');
        if (findValue && replaceWithValue) {
            var reqData = new FormData();
            reqData.append('findValue', findValue);
            reqData.append('replaceWithValue', replaceWithValue);
            doRequest(window.cmsurl + '/core/functions/ReplaceURLS.php', reqData).then(function (data) {
                if (data.status === true) {
                    alert('URL\'s zijn vervangen');
                }
                else {
                    alert('URL\'s zijn (mogelijk) vervangen, met errors (zie console)');
                    console.error('errors', {
                        'pageErrors': data.pageErrors,
                        'layerErrors': data.layerErrors,
                        'moduleErrors': data.moduleErrors,
                        'settingsErrors': data.settingsErrors
                    });
                }
            });
        }
        else {
            alert('Vul beide velden in a.u.b.');
        }
    });
    // Generate password
    generatePasswordButton === null || generatePasswordButton === void 0 ? void 0 : generatePasswordButton.addEventListener('click', function (e) {
        e.preventDefault();
        var alphabet = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz', symbols = '!@#$%^&*(){}[]=<>/', numbers = '0123456789', characters = alphabet + symbols + numbers, password = '';
        for (var i = 1; i <= 16; i++) {
            var index = Math.floor(Math.random() * characters.length + 1);
            password += characters.charAt(index);
        }
        passwordField.value = password;
    });
    // Show password
    showPasswordButton === null || showPasswordButton === void 0 ? void 0 : showPasswordButton.addEventListener('click', function (e) {
        e.preventDefault();
        var icon = showPasswordButton.querySelector('svg');
        var show = icon.querySelector('.show');
        var hide = icon.querySelector('.hide');
        if (passwordField.getAttribute('type') === 'password') {
            passwordField.setAttribute('type', 'text');
            hide.classList.add('active');
            show.classList.remove('active');
            showPasswordButton.title = 'Verberg wachtwoord';
        }
        else {
            passwordField.setAttribute('type', 'password');
            show.classList.add('active');
            hide.classList.remove('active');
            showPasswordButton.title = 'Toon wachtwoord';
        }
    });
});
