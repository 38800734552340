import { doRequest } from "../../js/functions";
import { createCKEditors, resetCKEditorsInParent } from "../../js/editor";
import { makeTableSortable } from "../../js/sort";
export var loadLayers = function (parentID, parentType) {
    //Determine request URL
    var requestURL = window.cmsurl + '/layers/handlers/layer_handler.php';
    //Compile the data
    var data = new FormData();
    data.append('request', 'getLayerTable');
    data.append('parentID', parentID.toString());
    data.append('parentType', parentType);
    doRequest(requestURL, data)
        .then(function (data) {
        var layerContainer = document.querySelector('.layer-overview .content-grid .layer-table-container');
        //Clear the layer overview (if necessary)
        if (data.status === true) {
            layerContainer.innerHTML = data.content;
            var layerTable = layerContainer.querySelector('.cms-table.sortable');
            layerTable && makeTableSortable(layerTable);
            //Bind edit events
            var editLayers = document.querySelectorAll('.edit-layer');
            editLayers && editLayers.forEach(function (editLayer) {
                editLayer.addEventListener('click', function (e) {
                    e.preventDefault();
                    var currentType = editLayer.parentElement.parentElement.getAttribute('data-currenttype'), existingID = editLayer.parentElement.parentElement.getAttribute('data-rowid');
                    //Load lader
                    getLayerForm(parseInt(currentType), parseInt(existingID));
                    //Change button text
                    document.querySelector('.layer-submit').textContent = 'Wijzigingen opslaan';
                    document.querySelector('.layer-overview').classList.remove('active');
                    document.querySelector('.layer-modify').classList.add('active');
                });
            });
            //Set basic variables
            var handler_1 = window.window.cmsurl + '/structure/handlers/struct_handler.php', table_1 = 'cms_layers';
            //Bind deletion event
            var deleteLayers = document.querySelectorAll('.delete-layer');
            deleteLayers && deleteLayers.forEach(function (deleteLayer) {
                deleteLayer.addEventListener('click', function (e) {
                    e.preventDefault();
                    var row = deleteLayer.parentElement.parentElement, name = row.getAttribute('data-name'), id = row.getAttribute('data-rowid'), fd = new FormData();
                    if (confirm('Weet u zeker dat u het item met de naam ' + name + ' wilt verwijderen?')) {
                        fd.append('request', 'delete');
                        fd.append('db_table', table_1);
                        fd.append('id', id);
                        doRequest(handler_1, fd)
                            .then(function (data) {
                            if (data.status === true) {
                                row.remove();
                                //See new count
                                var newCount = document.querySelectorAll('.layer-table tbody tr').length;
                                if (newCount === 0) {
                                    window.location.reload();
                                }
                            }
                        });
                    }
                });
            });
            //Bind duplicate event
            var duplicateLayer = document.querySelectorAll('.copy-layer');
            duplicateLayer && duplicateLayer.forEach(function (button) {
                button.addEventListener('click', function () {
                    var row = button.parentElement.parentElement, name = row.getAttribute('data-name'), id = row.getAttribute('data-rowid'), fd = new FormData();
                    // if (confirm('Weet u zeker dat u het item met de naam ' + name + ' wilt dupliceren?')) {
                    fd.append('request', 'duplicate');
                    fd.append('db_table', table_1);
                    fd.append('id', id);
                    doRequest(handler_1, fd)
                        .then(function (data) {
                        if (data.status === true) {
                            loadLayers(parentID, parentType);
                        }
                    });
                    // }
                });
            });
            //Bind visiblilty event
            var switchVisibility = document.querySelectorAll('.switch-layer-visibility');
            switchVisibility && switchVisibility.forEach(function (button) {
                button.addEventListener('click', function () {
                    var row = button.parentElement.parentElement, name = row.getAttribute('data-name'), newVis, id = row.getAttribute('data-rowid'), fd = new FormData();
                    if (button.classList.contains('visible')) {
                        newVis = 0;
                        button.classList.remove('visible');
                    }
                    else {
                        newVis = 1;
                        button.classList.add('visible');
                    }
                    fd.append('request', 'switch-visibility');
                    fd.append('db_table', table_1);
                    fd.append('id', id);
                    fd.append('new-visibility', newVis.toString());
                    doRequest(handler_1, fd);
                });
            });
        }
        else {
        }
    });
};
export var resetLayerForm = function () {
    var layerForm = document.querySelector('.layer-form');
    //Unset value properties
    var allValueElements = document.querySelectorAll('.layer-form input[type=text][value], .layer-form input[name="layerToUpdate"]');
    allValueElements && allValueElements.forEach(function (valueElement) {
        valueElement.setAttribute('value', '');
    });
    //Unset value properties
    var allCheckboxElements = document.querySelectorAll('.layer-form input[type="checkbox"], .layer-form input[type="radio"]');
    allCheckboxElements && allCheckboxElements.forEach(function (valueElement) {
        valueElement.checked = false;
        valueElement.removeAttribute('checked');
    });
    //Loop CKEDITORS
    var ckeditors = layerForm.querySelectorAll('.load-ckeditor');
    ckeditors && ckeditors.forEach(function (ckeditor) {
        var editorName = ckeditor.getAttribute('name');
        // window.CKEDITOR.instances[editorName].setData('');
    });
    //Reset the form elements
    layerForm.setAttribute('data-mode', 'neutral');
    layerForm.reset();
    //Clear out the fields
    var fieldContainers = layerForm.querySelectorAll('.main-fields, .extra-fields');
    fieldContainers && fieldContainers.forEach(function (fieldContainer) {
        fieldContainer.innerHTML = '';
    });
};
export var getLayerForm = function (layerTemplate, existingData) {
    if (layerTemplate === void 0) { layerTemplate = null; }
    if (existingData === void 0) { existingData = null; }
    //Determine request URL
    var requestURL = window.cmsurl + '/layers/handlers/layer_handler.php';
    //Compile the data
    var data = new FormData();
    data.append('request', 'getLayerForm');
    data.append('layerTemplate', layerTemplate.toString());
    if (existingData != null) {
        //Pre-select the right template
        document.getElementById('layer_template_' + layerTemplate).setAttribute('checked', 'checked');
        data.append('existingData', existingData.toString());
        document.querySelector('.layer-form').setAttribute('data-mode', 'editing');
    }
    else {
        document.querySelector('.layer-form').setAttribute('data-mode', 'adding');
    }
    doRequest(requestURL, data)
        .then(function (data) {
        //Loop lang main fields
        for (var lang in data.mainFields) {
            document.querySelector('.layer-form .main-fields[data-lang="' + lang + '"]').innerHTML = data.mainFields[lang];
        }
        for (var lang in data.extraFields) {
            document.querySelector('.layer-form .extra-fields[data-lang="' + lang + '"]').innerHTML = data.extraFields[lang];
        }
        document.querySelector('.request-data').innerHTML = data.requestData;
        //Bind CKEditor to text field
        resetCKEditorsInParent('layer');
        createCKEditors(window.cmsurl, window.site_url);
    });
};
