import { ready } from "./functions";
import { resetCKEditorsInParent, createCKEditors } from "./editor";
var langSwitcher = document.querySelector('.lang-switcher');
var langButtons = langSwitcher === null || langSwitcher === void 0 ? void 0 : langSwitcher.querySelectorAll('button');
var langContents = document.querySelectorAll('[data-lang]');
var langHash = window.location.hash;
// Change language function (on load/click)
var changeLanguage = function (lang) {
    window.lang = lang;
    // Change active button
    langButtons === null || langButtons === void 0 ? void 0 : langButtons.forEach(function (langButton) {
        if (langButton.classList.contains('active'))
            langButton.classList.remove('active');
        if (langButton.innerText.toLowerCase() === lang)
            langButton.classList.add('active');
    });
    // Change active content
    langContents === null || langContents === void 0 ? void 0 : langContents.forEach(function (langContent) {
        if (langContent.classList.contains('visible'))
            langContent.classList.remove('visible');
        if (langContent.getAttribute('data-lang') === lang)
            langContent.classList.add('visible');
    });
    var resetPageEditors = resetCKEditorsInParent('page');
    var resetModuleEditors = resetCKEditorsInParent('module');
    var resetLayerEditors = resetCKEditorsInParent('layer');
    Promise.all([resetPageEditors, resetModuleEditors, resetLayerEditors]).then(function () {
        createCKEditors(window.cmsurl, window.site_url);
    });
    // Change hash to active lang
    langHash = window.location.hash = lang;
};
ready(function (vars) {
    // Change when URL contains language hash
    if (langButtons && langHash) {
        changeLanguage(langHash.substring(1));
    }
    // Change on language button click
    langButtons === null || langButtons === void 0 ? void 0 : langButtons.forEach(function (langButton) {
        langButton.addEventListener('click', function () {
            if (langButton.classList.contains('active')) {
                return;
            }
            changeLanguage(langButton.innerText.toLowerCase());
        });
    });
});
