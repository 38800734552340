import Sortable from 'sortablejs';
import { doRequest, ready, onDrag } from './functions';
export var makeTableSortable = function (cmsTable) {
    var tableBody = cmsTable.querySelector('tbody');
    if (tableBody) {
        var dbTable_1 = cmsTable.getAttribute('data-dbtable'), orderHandler_1 = window.cmsurl + '/core/handlers/order_handler.php';
        var newOrder_1, tableRows_1;
        var sortableTable = Sortable.create(tableBody, {
            animation: 250,
            onStart: onDrag,
            onEnd: onDrag,
            onUpdate: function (evt) {
                newOrder_1 = new FormData();
                //We updated the table order, calculate order and write to db
                tableRows_1 = tableBody.querySelectorAll('tr');
                tableRows_1.forEach(function (data, index) {
                    newOrder_1.append(data.getAttribute('data-rowid'), index.toString());
                });
                newOrder_1.append('table', dbTable_1);
                doRequest(orderHandler_1, newOrder_1);
            }
        });
    }
};
ready(function (vars) {
    var cmsTables = document.querySelectorAll('.cms-table.sortable');
    if (cmsTables) {
        cmsTables.forEach(function (cmsTable) {
            makeTableSortable(cmsTable);
        });
    }
});
