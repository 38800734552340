import { doRequest, ready, slideDown } from './functions';
ready(function (vars) {
    //Submit login form
    var loginForm = document.querySelector(".base-login-form");
    loginForm && loginForm.addEventListener("submit", function (e) {
        e.preventDefault();
        var fd = new FormData(loginForm);
        var submitButton = e.submitter;
        submitButton.classList.add('loading');
        doRequest(window.cmsurl + '/core/handlers/login_handler.php', fd)
            .then(function (data) {
            var message = loginForm.querySelector(".message");
            message.innerHTML = data.message;
            if (data.status === true) {
                message.classList.remove('error');
                message.classList.add('success');
                loginForm.reset();
                //We need to redirect the user to the CMS main page
                if (data.redirect != null) {
                    window.location.href = data.redirect;
                }
            }
            else {
                message.classList.remove('success');
                message.classList.add('error');
            }
            submitButton.classList.remove('loading');
            slideDown(message);
        });
    });
    //Logout
    var logoutBtn = document.getElementById('logout');
    logoutBtn && logoutBtn.addEventListener('click', function (e) {
        var fd = new FormData();
        fd.append('request', 'logout');
        doRequest(window.cmsurl + '/core/handlers/login_handler.php', fd)
            .then(function (data) {
            window.location.href = window.cmsurl;
        });
    });
});
//-----------------------------------------------------
// Strong password function
//-----------------------------------------------------
var strongPasswordContainers = document.querySelector('.create-strong-password-container'), strongPasswordField = strongPasswordContainers === null || strongPasswordContainers === void 0 ? void 0 : strongPasswordContainers.querySelector('.require-strong-pass-field'), passwordRequirementPopup = document.querySelector('.password-requirements-popup'), strongPasswordParentForm = strongPasswordContainers === null || strongPasswordContainers === void 0 ? void 0 : strongPasswordContainers.parentElement.parentElement, strongPasswordParentFormSubmit = strongPasswordParentForm === null || strongPasswordParentForm === void 0 ? void 0 : strongPasswordParentForm.querySelector('button[type="submit"]');
if (strongPasswordContainers) {
    strongPasswordParentFormSubmit.setAttribute('disabled', 'disabled');
}
strongPasswordField === null || strongPasswordField === void 0 ? void 0 : strongPasswordField.addEventListener('input', function (e) {
    e.preventDefault();
    var currentInput = strongPasswordField.value, checkSucceeded = true;
    //-----------------------------------------------------
    // Check length
    //-----------------------------------------------------
    if (currentInput.length >= 12) {
        passwordRequirementPopup.querySelector('[data-requirement="minCharacter"]').classList.add('requirement-completed');
    }
    else {
        checkSucceeded = false;
        passwordRequirementPopup.querySelector('[data-requirement="minCharacter"]').classList.remove('requirement-completed');
    }
    //-----------------------------------------------------
    // Check lowercase
    //-----------------------------------------------------
    var lowercaseCharacterRegEx = new RegExp('[a-z]+', 'g');
    if (lowercaseCharacterRegEx.test(currentInput)) {
        passwordRequirementPopup.querySelector('[data-requirement="lowercaseLetter"]').classList.add('requirement-completed');
    }
    else {
        checkSucceeded = false;
        passwordRequirementPopup.querySelector('[data-requirement="lowercaseLetter"]').classList.remove('requirement-completed');
    }
    //-----------------------------------------------------
    // Get uppercase
    //-----------------------------------------------------
    var uppercaseCharacterRegEx = new RegExp('[A-Z]+', 'g');
    if (uppercaseCharacterRegEx.test(currentInput)) {
        passwordRequirementPopup.querySelector('[data-requirement="uppercaseLetter"]').classList.add('requirement-completed');
    }
    else {
        checkSucceeded = false;
        passwordRequirementPopup.querySelector('[data-requirement="uppercaseLetter"]').classList.remove('requirement-completed');
    }
    //-----------------------------------------------------
    // Check number
    //-----------------------------------------------------
    var numericCharacterRegEx = new RegExp('[0-9]+', 'g');
    if (numericCharacterRegEx.test(currentInput)) {
        passwordRequirementPopup.querySelector('[data-requirement="singleNumber"]').classList.add('requirement-completed');
    }
    else {
        checkSucceeded = false;
        passwordRequirementPopup.querySelector('[data-requirement="singleNumber"]').classList.remove('requirement-completed');
    }
    //-----------------------------------------------------
    // Check special
    //-----------------------------------------------------
    var specialCharacterRegEx = new RegExp('[$@#&!^*,.%]+', 'g');
    if (specialCharacterRegEx.test(currentInput)) {
        passwordRequirementPopup.querySelector('[data-requirement="specialCharacter"]').classList.add('requirement-completed');
    }
    else {
        checkSucceeded = false;
        passwordRequirementPopup.querySelector('[data-requirement="specialCharacter"]').classList.remove('requirement-completed');
    }
    //-----------------------------------------------------
    // Enable the fields
    //-----------------------------------------------------
    if (checkSucceeded) {
        strongPasswordParentFormSubmit.removeAttribute('disabled');
    }
    else {
        strongPasswordParentFormSubmit.setAttribute('disabled', 'disabled');
    }
});
