import { ready } from "./functions";
var body = document.querySelector('body');
var nav = document.querySelector('.cms-nav-container');
ready(function () {
    if (nav) {
        //Listen to nav menu opening toglle
        var menuToggle_1 = document.querySelector('.menu-toggle');
        menuToggle_1 === null || menuToggle_1 === void 0 ? void 0 : menuToggle_1.addEventListener('click', function (e) {
            e.preventDefault();
            if (!nav.classList.contains('open')) {
                menuToggle_1.classList.remove('closed');
                menuToggle_1.classList.add('open');
                nav.classList.add('open');
                body.classList.add('nav-open');
            }
            else {
                menuToggle_1.classList.remove('open');
                menuToggle_1.classList.add('closed');
                nav.classList.remove('open');
                body.classList.remove('nav-open');
            }
        });
        //Close nav when clicking anywhere
        document.addEventListener('click', function (e) {
            if ((e.target != nav && e.target != menuToggle_1) && nav.classList.contains('open')) {
                menuToggle_1.classList.remove('open');
                menuToggle_1.classList.add('closed');
                nav.classList.remove('open');
                body.classList.remove('nav-open');
            }
        });
    }
});
