import { doRequest, ready, onDrag } from "./functions";
import Sortable from 'sortablejs';
;
var formattedType = {
    'page': 'Pagina',
    'external': 'Externe link',
    'submenu': 'Submenu'
};
var onSort = function (e) {
    document.querySelector('.menu-structure').classList.remove('sorting');
    if (e.item.parentElement && e.item.parentElement.classList.contains('sub-items') && e.item.classList.contains('.menu-item')) {
        //Remove "menu-item"
        var innerContent = e.item.querySelector('.item').outerHTML;
        e.item.outerHTML = innerContent;
    }
    else if (e.item.parentElement && e.item.parentElement.classList.contains('menu-structure')) {
        //Append "menu-item"
        var curHTML = e.item.outerHTML, newHTML = '<div class="menu-item">' + curHTML + '<div class="sub-items"></div></div>';
        e.item.outerHTML = newHTML;
        //Call sortable here
        var newSortableEntries = document.querySelectorAll('.sub-items:not(.sortable)');
        newSortableEntries.forEach(function (sortableEntry) {
            sortableEntry.classList.add('sortable');
            new Sortable(sortableEntry, {
                group: 'menu-sorting',
                filter: '.subpage',
                animation: 250,
                onSort: onSort,
                onStart: onDrag,
                onEnd: onDrag
            });
        });
    }
    rebuildMenuArray();
};
var rebuildMenuArray = function (refreshAfter) {
    if (refreshAfter === void 0) { refreshAfter = false; }
    var menuSelector = document.getElementById('menu-selector'), menuToRebuild = menuSelector.value;
    var menuObject = [];
    var menuEntriesContainer = document.querySelector('.menu-structure'), menuEntries = menuEntriesContainer.querySelectorAll(':scope > .menu-item');
    menuEntries.forEach(function (menuEntry) {
        //Fetch details about the main page
        var mainItem = menuEntry.querySelector('.item'), itemArray = {
            identifier: mainItem.getAttribute('data-identifier'),
            type: mainItem.getAttribute('data-type'),
            visiblename: JSON.parse(mainItem.getAttribute('data-visiblename')),
            href: mainItem.getAttribute('data-href'),
            icon: (mainItem.getAttribute('data-icon') != '' ? mainItem.getAttribute('data-icon') : null)
        };
        //Loop the submenus
        var submenuArray = [];
        var submenus = menuEntry.querySelectorAll('.sub-items .item:not(.subpage)');
        submenus && submenus.forEach(function (subItem) {
            submenuArray.push({
                identifier: subItem.getAttribute('data-identifier'),
                type: subItem.getAttribute('data-type'),
                visiblename: JSON.parse(subItem.getAttribute('data-visiblename')),
                href: subItem.getAttribute('data-href')
            });
        });
        itemArray['submenu'] = submenuArray;
        menuObject.push(itemArray);
    });
    var fd = new FormData();
    fd.append('request', 'sort');
    fd.append('menu', menuToRebuild);
    fd.append('neworder', JSON.stringify(menuObject));
    //Create a POST request to make changes permanent
    doRequest(window.cmsurl + '/structure/handlers/menu_handler.php', fd).then(function () {
        getNewPageList();
        if (refreshAfter === true) {
            window.location.reload();
        }
    });
};
var deleteEntry = function (deleteButton) {
    var parentEl = deleteButton.parentElement, menuEntry = parentEl.parentElement;
    var nameArr = JSON.parse(parentEl.getAttribute('data-visiblename'));
    var nameOfEntry = nameArr[Object.keys(nameArr)[0]];
    if (confirm('Weet u zeker dat u het item met de naam ' + nameOfEntry + ' wilt verwijderen?')) {
        menuEntry.remove();
        rebuildMenuArray(true);
    }
};
var editEntry = function (editButton) {
    //Close all other edit forms
    var openEditForms = document.querySelectorAll('.edit-form.visible');
    openEditForms && openEditForms.forEach(function (openEditForm) {
        openEditForm.classList.remove('visible');
        openEditForm.removeAttribute('style');
    });
    //Show edit entry
    var editForm = editButton.parentElement.querySelector('.edit-form');
    if (!editForm.classList.contains('visible')) {
        editForm.style.padding = '1rem';
        editForm.style.maxHeight = editForm.scrollHeight + 'px';
        editForm.classList.add('visible');
    }
    else {
        editForm.removeAttribute('style');
        editForm.classList.remove('visible');
    }
};
var saveEditedEntry = function (saveButton) {
    //Get the parentform
    var parentForm = saveButton.parentElement, parentItem = parentForm.parentElement, type = parentItem.getAttribute('data-type');
    //Fetch and set the values
    var visibleNameEls = parentForm.querySelectorAll('input[name*="visiblename"]');
    var visibleNameArr = {};
    var visibleNamePrimary;
    //Loop Visible Names
    visibleNameEls.forEach(function (visibleNameField, key) {
        //Set new attributes for name fields
        var langOfField = visibleNameField.getAttribute('data-lang');
        visibleNameArr[langOfField] = visibleNameField.value;
        if (key === 0) {
            visibleNamePrimary = visibleNameField.value;
        }
    });
    var visibleName = JSON.stringify(visibleNameArr);
    parentItem.setAttribute('data-visiblename', visibleName);
    var href;
    if (type == 'page') {
        href = parentForm.querySelector('select[name="href"]').value;
    }
    else if (type == 'external') {
        href = parentForm.querySelector('input[name="href"]').value;
    }
    if (href) {
        parentItem.setAttribute('data-href', href);
    }
    var iconField = parentForm.querySelector('.menu-icon-edit input');
    var icon = iconField && iconField.value;
    if (icon) {
        parentItem.setAttribute('data-icon', icon);
    }
    else {
        parentItem.setAttribute('data-icon', '');
    }
};
var pageAddSelect = document.querySelector('.page-add-select');
var getNewPageList = function () {
    var fd = new FormData();
    fd.append('request', 'get-addable-pages');
    fd.append('chapterToLoad', window.pad2);
    pageAddSelect.innerHTML = '<option selected disabled value="">Kies een pagina</option>';
    doRequest(window.cmsurl + '/structure/handlers/menu_handler.php', fd).then(function (data) {
        if (data.status) {
            data.pages.forEach(function (pageDetails) {
                var newOptionEl = document.createElement('option');
                newOptionEl.value = pageDetails.id;
                newOptionEl.textContent = pageDetails.page_title_nl;
                pageAddSelect.append(newOptionEl);
            });
        }
    });
};
if (pageAddSelect) {
    getNewPageList();
}
ready(function (vars) {
    var menuEntriesContainer = document.querySelector('.menu-structure');
    var menuSelector = document.querySelector('.choose-menu');
    menuSelector && menuSelector.addEventListener('change', function (e) {
        if (menuSelector.value != '') {
            var menuChosen = menuSelector.value;
            window.location.href = window.cmsurl + '/menu/' + menuChosen;
        }
    });
    //Choose an entry
    var newFieldSelectors = document.querySelectorAll('form.add-menu input[name="new-field-selector"]');
    newFieldSelectors.forEach(function (newFieldSelector) {
        newFieldSelector.addEventListener('change', function (e) {
            //See what type it is
            var typeToAdd = newFieldSelector.value, typeToAddOption = document.querySelector('form.add-menu .optional-fields .field.add-' + typeToAdd), typeToAddField = typeToAddOption.querySelector('input[name*="visiblename_"]');
            //Hide all optional fields
            var optionalFieldsContainers = document.querySelectorAll('form.add-menu .optional-fields .field');
            optionalFieldsContainers && optionalFieldsContainers.forEach(function (optionalFieldsContainer) {
                optionalFieldsContainer.classList.add('hidden');
            });
            //Clear the optional fields
            var optionalFields = document.querySelectorAll('form.add-menu .optional-fields input, form.add-menu .optional-fields select');
            optionalFields && optionalFields.forEach(function (optionalField) {
                optionalField.value = '';
            });
            //Show the correct optional field
            typeToAddOption.classList.remove('hidden');
            document.querySelector('form.add-menu .field.add-button').classList.remove('hidden');
            //Focus visible name field
            typeToAddField.focus();
        });
    });
    //Add an entry
    var addEntry = document.querySelector('form.add-menu button.add-entry');
    addEntry && addEntry.addEventListener('click', function (e) {
        //We need to gather all the required properties for a new entry
        var typeEl = document.querySelector('form.add-menu input[name="new-field-selector"]:checked'), type = typeEl.value;
        //We need to fetch the visible name
        var visibleNameEls = document.querySelectorAll('form.add-menu .optional-fields .field:not(.hidden) input[name*="visiblename"]');
        //We need to fetch href
        var hrefEl = document.querySelector('form.add-menu .optional-fields .field:not(.hidden) input[name="href"]');
        if (!hrefEl) {
            hrefEl = document.querySelector('form.add-menu .optional-fields .field:not(.hidden) select[name="href"]');
        }
        var href = hrefEl && hrefEl.value;
        var iconField = document.querySelector('form.add-menu .optional-fields .field:not(.hidden) input[name="menu_icon"]');
        var icon = iconField && iconField.value;
        //Verify if all fields are valid
        if (type != '' && visibleNameEls.length > 0 && (href != '' || type == 'submenu')) {
            //Append the row to the structure
            var newEntry = document.createElement('div');
            newEntry.classList.add('menu-item');
            var newMenuEntry_1 = document.createElement('div');
            newMenuEntry_1.classList.add('item');
            newMenuEntry_1.setAttribute('data-identifier', crypto.randomUUID());
            newMenuEntry_1.setAttribute('data-type', type);
            newMenuEntry_1.setAttribute('data-href', href);
            newMenuEntry_1.setAttribute('data-icon', icon);
            newMenuEntry_1.setAttribute('title', 'Houd de linkermuisknop vast om dit item te verslepen');
            var visibleNameArr_1 = {};
            var visibleNamePrimary_1;
            var clonedFields_1 = Array();
            //Loop Visible Names
            visibleNameEls.forEach(function (visibleNameField, key) {
                //Set new attributes for name fields
                var langOfField = visibleNameField.getAttribute('data-lang');
                visibleNameArr_1[langOfField] = visibleNameField.value;
                if (key === 0) {
                    visibleNamePrimary_1 = visibleNameField.value;
                }
                //Clone the name fields
                var clonedNameField = visibleNameField.cloneNode(true);
                clonedNameField.setAttribute('value', visibleNameField.value);
                clonedFields_1.push(clonedNameField);
            });
            var visibleName = JSON.stringify(visibleNameArr_1);
            newMenuEntry_1.setAttribute('data-visiblename', visibleName);
            //Calculate entry details
            var entryDetails = '', hrefField = document.querySelector("form.add-menu .optional-fields .field.add-".concat(type, " [name=\"href\"]")), clonedHrefField = hrefField === null || hrefField === void 0 ? void 0 : hrefField.cloneNode(true);
            if (type == 'page') {
                entryDetails = visibleName != hrefEl.options[hrefEl.selectedIndex].text ? " <small>(".concat(hrefEl.options[hrefEl.selectedIndex].text, ")</small>") : '';
                clonedHrefField === null || clonedHrefField === void 0 ? void 0 : clonedHrefField.querySelector("option[value=\"".concat(href, "\"]")).setAttribute('selected', '');
            }
            else if (type == 'external') {
                entryDetails = " <small>(".concat(href, ")</small>");
                clonedHrefField === null || clonedHrefField === void 0 ? void 0 : clonedHrefField.setAttribute('value', href);
            }
            var clonedFieldHTML_1 = '';
            clonedFields_1.forEach(function (clonedField) {
                clonedFieldHTML_1 += clonedField.outerHTML;
            });
            newMenuEntry_1.innerHTML = "\n                    <p class=\"name\">".concat(visibleNamePrimary_1 + entryDetails, "</small></p>\n                    <p class=\"type\">").concat(formattedType[type], "</p>\n                    <button class=\"edit-menu\" title=\"Menu item bewerken\"><img src=\"").concat(window.cmsurl, "/content/img/icon-edit-orange.svg\"></button>\n                    <button class=\"delete-menu\"><img src=\"").concat(window.cmsurl, "/content/img/icon-delete-orange.svg\"></button>\n                    <form class=\"edit-form\">\n                        <fieldset class=\"grey-fields\">\n                            <div class=\"field\">\n                                ").concat(clonedFieldHTML_1, "\n                            </div>\n                            <div class=\"field\">\n                                ").concat(clonedHrefField === null || clonedHrefField === void 0 ? void 0 : clonedHrefField.outerHTML, "\n                            </div>\n                        </fieldset>\n                        <button class=\"small-button save-menu-edit\">Opslaan</button>\n                    </form>\n                ");
            newEntry.append(newMenuEntry_1);
            var subMenuList = document.createElement('div');
            subMenuList.classList.add('sub-items');
            newEntry.append(subMenuList);
            menuEntriesContainer.append(newEntry);
            //Hide all optional fields
            var optionalFieldsContainers = document.querySelectorAll('form.add-menu .optional-fields .field');
            optionalFieldsContainers && optionalFieldsContainers.forEach(function (optionalFieldsContainer) {
                optionalFieldsContainer.classList.add('hidden');
            });
            //Reset form
            var addForm = document.querySelector('form.add-menu');
            addForm.reset();
            var newSortableEntries = document.querySelectorAll('.sub-items:not(.sortable)');
            newSortableEntries.forEach(function (sortableEntry) {
                sortableEntry.classList.add('sortable');
                new Sortable(sortableEntry, {
                    group: 'menu-sorting',
                    filter: '.subpage',
                    animation: 250,
                    onSort: onSort,
                    onStart: onDrag,
                    onEnd: onDrag
                });
            });
            newMenuEntry_1.addEventListener('click', function (e) {
                e.preventDefault();
                var targetElement = e.target;
                var deleteButton = newMenuEntry_1.querySelector('.delete-menu'), editButton = newMenuEntry_1.querySelector('.edit-menu'), saveButton = newMenuEntry_1.querySelector('.save-menu-edit'), refreshAfter = false;
                if (deleteButton.contains(targetElement)) {
                    deleteEntry(deleteButton);
                }
                else if (editButton.contains(targetElement)) {
                    editEntry(deleteButton);
                }
                else if (saveButton.contains(targetElement)) {
                    saveEditedEntry(saveButton);
                    refreshAfter = true;
                }
                rebuildMenuArray(refreshAfter);
            });
            rebuildMenuArray();
        }
        else {
            alert('Vul aub geldige waarde in');
        }
    });
    //Edit an entry
    var editButtons = document.querySelectorAll('.menu-item .edit-menu');
    editButtons.forEach(function (editButton) {
        editButton.addEventListener('click', function (e) {
            e.preventDefault();
            editEntry(editButton);
        });
    });
    //Save a modified entry
    var saveButtons = document.querySelectorAll('.edit-form .save-menu-edit');
    saveButtons.forEach(function (saveButton) {
        saveButton.addEventListener('click', function (e) {
            e.preventDefault();
            saveEditedEntry(saveButton);
            rebuildMenuArray(true);
        });
    });
    //Delete an entry
    var deleteButtons = document.querySelectorAll('.menu-item .delete-menu');
    deleteButtons.forEach(function (deleteButton) {
        deleteButton.addEventListener('click', function (e) {
            e.preventDefault();
            deleteEntry(deleteButton);
        });
    });
    //Listen the page visiblename
    var autofilledVisibleNameInput = document.querySelector('input[name="visiblename"]');
    autofilledVisibleNameInput === null || autofilledVisibleNameInput === void 0 ? void 0 : autofilledVisibleNameInput.addEventListener('keydown', function (e) {
        var visibleNameEl = document.querySelector('input[name="visiblename"]');
        if (visibleNameEl.classList.contains('autofilled')) {
            visibleNameEl.classList.remove('autofilled');
        }
    });
    //Listen the page name
    var selectHREFs = document.querySelectorAll('select[name="href"]');
    selectHREFs.forEach(function (selectHREF) {
        selectHREF && selectHREF.addEventListener('change', function (e) {
            //Find the name of the current menu
            var selectedOption = selectHREF.value;
            var visibleNameElements = selectHREF.parentElement.querySelectorAll('input[name*="visiblename"]');
            var fd = new FormData();
            fd.append('request', 'get-pagetitles');
            fd.append('pageID', selectedOption);
            //Get page titles for all languages
            doRequest(window.cmsurl + '/structure/handlers/menu_handler.php', fd).then(function (data) {
                visibleNameElements.forEach(function (visibleNameEl) {
                    var lang = visibleNameEl.getAttribute('data-lang');
                    if (visibleNameEl.value === '' || visibleNameEl.classList.contains('autofilled')) {
                        visibleNameEl.value = data['page_title_' + lang];
                        visibleNameEl.classList.add('autofilled');
                    }
                });
            });
        });
    });
    //Trigger sortable
    var sortableEntries = document.querySelectorAll('.menu-structure, .sub-items');
    sortableEntries.forEach(function (sortableEntry) {
        sortableEntry.classList.add('sortable');
        new Sortable(sortableEntry, {
            group: 'menu-sorting',
            filter: '.subpage',
            animation: 250,
            onSort: onSort,
            onStart: onDrag,
            onEnd: onDrag
        });
    });
});
