import { ready, setCookie, getCookie } from './functions';
var darkModeState = false;
var themeToggleButton = document.querySelector('.theme-toggle');
// MediaQueryList object
var useDark = window.matchMedia('(prefers-color-scheme: dark)');
// Toggles the theme
function toggleDarkMode(state) {
    var darkTheme = state;
    if (darkTheme === true) {
        document.documentElement.classList.remove('theme-light');
        document.documentElement.classList.add('theme-dark');
    }
    else {
        document.documentElement.classList.remove('theme-dark');
        document.documentElement.classList.add('theme-light');
    }
    darkModeState = state;
    themeToggleButton === null || themeToggleButton === void 0 ? void 0 : themeToggleButton.setAttribute('data-cms-theme-dark', darkModeState.toString());
    toggleDarkModeFilemanager(darkModeState);
}
// Toggle theme in filemanager URL's
function toggleDarkModeFilemanager(state) {
    var fileManagerButtons = document.querySelectorAll('.open-lightbox'), mediaManagerIframe = document.querySelector('.media > iframe');
    fileManagerButtons === null || fileManagerButtons === void 0 ? void 0 : fileManagerButtons.forEach(function (fileManagerButton) {
        var url = new URL(fileManagerButton.getAttribute('data-lbcontent')), urlParams = url.searchParams;
        urlParams.set('cms-theme-dark', state.toString());
        url.search = urlParams.toString();
        fileManagerButton.setAttribute('data-lbcontent', url.toString());
    });
    if (mediaManagerIframe) {
        var url = new URL(mediaManagerIframe.src), urlParams = url.searchParams;
        urlParams.set('cms-theme-dark', state.toString());
        url.search = urlParams.toString();
        mediaManagerIframe.src = url.toString();
    }
}
// Sets Cookie state
function setDarkModeCookie(state) {
    setCookie('cms-theme-dark', state);
}
ready(function () {
    var _a;
    // Initial setting - check darkmode mediaquery
    toggleDarkMode((_a = (getCookie('cms-theme-dark') && getCookie('cms-theme-dark') === 'true')) !== null && _a !== void 0 ? _a : useDark.matches);
    // Listen for changes in the OS settings
    useDark.addEventListener('change', function (e) {
        toggleDarkMode(e.matches);
    });
    // Toggles the 'dark-mode' class on click and sets darkmode cookie state
    themeToggleButton === null || themeToggleButton === void 0 ? void 0 : themeToggleButton.addEventListener('click', function () {
        darkModeState = !darkModeState;
        toggleDarkMode(darkModeState);
        setDarkModeCookie(darkModeState);
    });
});
