import { ready, doRequest, slideUp, slideDown } from '../../js/functions';
import { loadLayers, resetLayerForm } from "./layer_functions";
import { getLayerForm } from "./layer_functions";
import tinymce from "tinymce";
ready(function (vars) {
    //See if we need to fill layers
    var layersParent = document.querySelector('.cms-layers');
    if (layersParent) {
        loadLayers(parseInt(layersParent.getAttribute('data-parentid')), layersParent.getAttribute('data-parenttype'));
    }
    //Open add layer form
    var addLayer = document.querySelector('.add-layer');
    addLayer && addLayer.addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelector('.layer-submit').textContent = 'Laag toevoegen';
        //Remove visibility from list
        document.querySelector('.layer-overview').classList.remove('active');
        document.querySelector('.layer-modify').classList.add('active');
    });
    //Close layer add form
    var closeLayer = document.querySelector('.layer-back');
    closeLayer && closeLayer.addEventListener('click', function (e) {
        e.preventDefault();
        document.querySelector('.layer-submit').textContent = '';
        //Remove visibility from list
        document.querySelector('.layer-modify').classList.remove('active');
        document.querySelector('.layer-overview').classList.add('active');
        resetLayerForm();
    });
    //Change Layer Type
    var changeLayerType = document.querySelectorAll('.change-layer-type');
    changeLayerType && changeLayerType.forEach(function (layerType) {
        layerType.addEventListener('change', function (e) {
            e.preventDefault();
            var selectedTemplate = parseInt(layerType.getAttribute('value'));
            //See if we are in editing mode
            if (document.querySelector('.layer-form').getAttribute('data-mode') == 'editing') {
                //Load the layer form
                getLayerForm(selectedTemplate, parseInt(document.querySelector('input[name="layerToUpdate"]').getAttribute('value')));
            }
            else {
                //Load the layer form
                getLayerForm(selectedTemplate, null);
            }
        });
    });
    //Submit layer form
    var layerForm = document.querySelector('.layer-form');
    layerForm && layerForm.addEventListener('submit', function (e) {
        e.preventDefault();
        //Handle layer form submit
        var layerFD = new FormData(layerForm);
        //Loop CKEDITORS
        // let ckeditors = layerForm.querySelectorAll('.load-ckeditor');
        // ckeditors && ckeditors.forEach((ckeditor) => {
        //     let editorName = ckeditor.getAttribute('name');
        //     // layerFD.set(editorName, window.CKEDITOR.instances[editorName].getData())
        // });
        tinymce.get().forEach(function (editor) {
            layerFD.set(editor.getElement().getAttribute('name'), editor.getContent());
        });
        //Loop (unchecked) checkboxes and append a value of 0 for the query
        var checkboxes = layerForm.querySelectorAll('input[type=checkbox]:not(:checked)');
        checkboxes.forEach(function (cb) {
            layerFD.append(cb.getAttribute('name'), '0');
        });
        doRequest(layerForm.getAttribute('action'), layerFD)
            .then(function (data) {
            var message = layerForm.querySelector('.message');
            if (data.status === true) {
                //Clear form
                resetLayerForm();
                //Reload the list
                loadLayers(parseInt(layersParent.getAttribute('data-parentid')), layersParent.getAttribute('data-parenttype'));
                //Make the views visible again
                document.querySelector('.layer-modify').classList.remove('active');
                document.querySelector('.layer-overview').classList.add('active');
                message.textContent = '';
            }
            else {
                //Display a message (if necessary);
                message.classList.remove('success');
                message.classList.add('error');
                message.textContent = data.message;
                slideDown(message);
                setTimeout(function () {
                    slideUp(message);
                }, 2000);
            }
        });
    });
});
