import { ready, slideUp, slideDown } from "./functions";
var metaDescriptionFields = document.querySelectorAll('.meta-desc-field');
ready(function (vars) {
    document.addEventListener('click', function (e) {
        var targetElement = e.target;
        // Open image alt-tag fields
        if (targetElement.classList.contains('alt-button') && !targetElement.classList.contains('generate-password')) {
            var altTagFieldsToShow = targetElement.parentElement.getAttribute('for'), altFieldsWrap = document.querySelector(".alt-fields#".concat(altTagFieldsToShow, "_alt")), altfields = altFieldsWrap === null || altFieldsWrap === void 0 ? void 0 : altFieldsWrap.querySelectorAll('input');
            if (altFieldsWrap) {
                if (altFieldsWrap.hasAttribute('hidden')) {
                    slideDown(altFieldsWrap, 'flex');
                    altFieldsWrap.removeAttribute('hidden');
                    altfields === null || altfields === void 0 ? void 0 : altfields.forEach(function (altField) {
                        if (altField.id.endsWith(window.lang)) {
                            altField.focus();
                        }
                    });
                }
                else {
                    slideUp(altFieldsWrap);
                    altFieldsWrap.setAttribute('hidden', 'hidden');
                }
            }
        }
        // Clear image field
        if (targetElement.classList.contains('clear-field')) {
            var imageFieldToClear = targetElement.getAttribute('data-field'), imageField = document.querySelector('input[name="' + imageFieldToClear + '"]'), altFieldsWrap = document.querySelector(".alt-fields#".concat(imageFieldToClear, "_alt")), altfields = altFieldsWrap === null || altFieldsWrap === void 0 ? void 0 : altFieldsWrap.querySelectorAll('input'), previewImage = imageField.parentElement.querySelector('.image-preview'), popupButton = imageField.parentElement.querySelector('.open-lightbox');
            if (imageField) {
                if (previewImage && previewImage.classList.contains('image-preview')) {
                    previewImage.remove();
                }
                if (popupButton) {
                    var buttonURL = new URL(popupButton.getAttribute('data-lbcontent'));
                    buttonURL.searchParams.delete('file');
                    popupButton.setAttribute('data-lbcontent', buttonURL.toString());
                }
                imageField.value = '';
                imageField.removeAttribute('value');
                altfields === null || altfields === void 0 ? void 0 : altfields.forEach(function (altField) {
                    altField.value = '';
                });
                targetElement.removeAttribute('style');
            }
        }
    });
    // Meta description field counter
    metaDescriptionFields === null || metaDescriptionFields === void 0 ? void 0 : metaDescriptionFields.forEach(function (metaDescriptionField) {
        var metaDescriptionCounter = (metaDescriptionField.previousElementSibling.querySelector('.meta-desc-field-counter'));
        if (metaDescriptionCounter) {
            metaDescriptionField.addEventListener('keyup', function (e) {
                var characterCount = metaDescriptionField.value.length;
                metaDescriptionCounter.innerText = characterCount.toString();
            });
        }
    });
});
