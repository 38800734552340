var adminChapterPermissionsFields = document.querySelectorAll('input[name="admin_chapter_permissions[]"]');
var adminPagePermissionsField = document.querySelector('select[name="admin_page_permissions[]"]');
var markChapterPagesInSelect = function () {
    //Clear all page options
    adminPagePermissionsField.querySelectorAll('option').forEach(function (pageOption) {
        pageOption.removeAttribute('disabled');
        pageOption.classList.remove('page-part-of-chapter');
    });
    adminChapterPermissionsFields.forEach(function (adminChapterPermissionsField) {
        if (adminChapterPermissionsField.checked) {
            adminPagePermissionsField.querySelectorAll('option[data-chapter="' + adminChapterPermissionsField.value + '"]').forEach(function (pageOptionInChapter) {
                pageOptionInChapter.classList.add('page-part-of-chapter');
                pageOptionInChapter.selected = false;
                pageOptionInChapter.setAttribute('disabled', 'disabled');
            });
        }
    });
};
adminChapterPermissionsFields.forEach(function (adminChapterPermissionsField) {
    adminChapterPermissionsField.addEventListener('click', function (e) {
        markChapterPagesInSelect();
    });
});
