var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Chart from "chart.js/auto";
import { ready, doRequest } from "./functions";
var charts = {};
var situationUnitPrefix = 'unit-';
var situationUnitUsesLeadingZeroes = false;
//-----------------------------------------------------
// Get the FormData needed for all requests
//-----------------------------------------------------
var getStatRequestData = function (table) {
    //Create the FormData object and append
    var reqData = new FormData();
    reqData.append('table', table);
    //Create the filter array
    var filterArray = {};
    //Fill the filter array
    var filterSelects = document.querySelectorAll('form#chart-filter-form select, form#chart-filter-form input');
    filterSelects && filterSelects.forEach(function (filterSelect) {
        //Declare the field name
        var fieldName = filterSelect.getAttribute('name'), filterValue = null;
        //Check what type of filter we are
        if (filterSelect.tagName == 'SELECT') {
            //Fetch the value and field name
            filterValue = filterSelect.value;
        }
        else {
            var inputType = filterSelect.getAttribute('type');
            if (inputType == 'checkbox') {
                filterValue = (filterSelect.checked ? 1 : 0);
            }
            else {
                filterValue = filterSelect.value;
            }
        }
        //Only append the filter if it's not empty
        if (filterValue != 'all' && filterValue != '' && filterValue != null) {
            //Append in to the array
            filterArray[fieldName] = filterValue;
        }
    });
    //Append the filter JSON to the FormData object
    reqData.append('filters', JSON.stringify(filterArray));
    //Return the valid FormData object
    return reqData;
};
//-----------------------------------------------------
// Fetch the raw chart data
//-----------------------------------------------------
var fetchChartData = function (table, field) { return __awaiter(void 0, void 0, void 0, function () {
    var reqData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                reqData = getStatRequestData(table);
                //Append custom request data
                reqData.append('chartType', 'pie');
                reqData.append('field', field);
                return [4 /*yield*/, doRequest(window.cmsurl + '/forms/handlers/get_chart_data.php', reqData)];
            case 1: 
            //To the request
            return [2 /*return*/, _a.sent()];
        }
    });
}); };
//-----------------------------------------------------
// Create the pie chart
//-----------------------------------------------------
var createBasicChart = function (data, field, type, generateLegend) {
    if (generateLegend === void 0) { generateLegend = true; }
    //Create basic Chart.JS arrays
    var labels = [];
    var dataset = [];
    var colors = [];
    //Select the chart legend element
    var chartLegendContainer = document.querySelector('.stat-row[data-chart="' + field + '"] .chart-legend');
    var list = document.createElement('ul');
    //Fill the list with the fetched data
    for (var i = 0; i < data.sortingMap.length; i++) {
        //Get the value
        var value = data.sortingMap[i];
        var generatedColor = 'rgba(' + Math.floor(Math.random() * 256) + ',' + Math.floor(Math.random() * 256) + ',' + Math.floor(Math.random() * 256) + ', 1)';
        //Push the arrays (Chart.JS)
        labels.push(data.arrayMap[value]);
        dataset.push(data.rawData[value]);
        colors.push(generatedColor);
        //Create the list item for the legend
        var listItem = document.createElement('li');
        listItem.innerHTML = '<div class="color" style="background-color: ' + generatedColor + ';"></div>' + data.arrayMap[value] + ' - ' + data.rawData[value].toString();
        list.append(listItem);
    }
    //Clear the existing legend and fill the new one
    if (generateLegend && chartLegendContainer) {
        chartLegendContainer.innerHTML = '';
        chartLegendContainer.append(list);
    }
    //Create the actual chart (if chart doesn't exist)
    if (charts[field] === undefined) {
        //Find the chart canvas
        var chartCanvas = document.getElementById('chart-' + field), chartConfig = {
            type: type,
            data: {
                labels: labels,
                datasets: [{
                        data: dataset,
                        backgroundColor: colors,
                        borderWidth: 0,
                    }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        };
        if (type == 'bar') {
            chartConfig['options']['scales'] = {
                y: {
                    min: 0,
                    suggestedMax: 15
                }
            };
        }
        //Create new ChartJS Chart in the chart array
        charts[field] = (new Chart(chartCanvas, chartConfig));
    }
    //Update the chart data (if the chart exists)
    else {
        charts[field].data.labels = labels;
        charts[field].data.datasets[0].data = dataset;
        charts[field].data.datasets[0].backgroundColor = colors;
        charts[field].update();
    }
    //Append the filters
    var chartFilters = document.querySelector('.field[data-field="' + field + '"] select');
    if (chartFilters) {
        //Fetch current value
        var currentValue = chartFilters.value;
        //Clear existing filters
        chartFilters.innerHTML = '<option value="all">Alles</option>';
        //Loop all the possible filters
        for (var _i = 0, _a = Object.entries(data.arrayMap); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            var val = value.toString();
            //Create the option element
            var option = document.createElement('option');
            option.value = key;
            option.textContent = val;
            //Check the option element if the filter is active
            if (key == currentValue) {
                option.selected = true;
            }
            //Append the chart filter
            chartFilters.append(option);
        }
        //Confirm field filled
        chartFilters.parentElement.setAttribute('data-filled', 'true');
    }
};
var openUnitPopup = function (table, unitSituationContainer, unitNumber, e) {
    var reqData = getStatRequestData(table);
    reqData.append('unit-number', unitNumber.toString());
    doRequest(window.cmsurl + '/forms/handlers/realestate/get_realestate_popup.php', reqData).then(function (data) {
        //Locate the popup DOM
        var popupContainer = document.querySelector('.realestate-units-situation-popup');
        //Determine the clicked position
        var unitSituationContainerPosition = unitSituationContainer.getBoundingClientRect();
        var popupXPercentage = (e.clientX - unitSituationContainerPosition.left) / unitSituationContainerPosition.width * 100, popupYPercentage = (e.clientY - unitSituationContainerPosition.top) / unitSituationContainerPosition.height * 100;
        popupContainer.style.top = popupYPercentage + '%';
        popupContainer.style.left = popupXPercentage + '%';
        popupContainer.querySelector('.realestate-units-situation-popup-content').innerHTML = data.table;
        popupContainer.classList.add('visible');
    });
};
var fillUnitSituation = function (unitSituationContainer, data) {
    var _a;
    var unitSVG = unitSituationContainer.querySelector('svg'), unitSVGPosition = unitSVG.getBoundingClientRect();
    //Clear existing text elements
    var existingTextOnMap = unitSVG.querySelectorAll('.realestate-height');
    existingTextOnMap === null || existingTextOnMap === void 0 ? void 0 : existingTextOnMap.forEach(function (existingEntryOnMap) {
        existingEntryOnMap.remove();
    });
    //Close existing popup
    var realestatePopup = unitSituationContainer.querySelector('.realestate-units-situation-popup');
    realestatePopup === null || realestatePopup === void 0 ? void 0 : realestatePopup.classList.remove('visible');
    //Loop all the units we have on the SVG
    Object.keys(data.unitCounterNoID).forEach(function (unitNr, i) {
        var peopleInterestedInUnit = data.unitCounterNoID[unitNr];
        //Check if we can find the unit number on the SVG
        var unitNrOnSVG = unitSVG.querySelector('[id*="' + situationUnitPrefix + unitNr + '"]');
        if (unitNrOnSVG) {
            //Calculate the center position of the unit]
            var unitPosition = unitNrOnSVG.getBoundingClientRect();
            var unitLeftPositionInElement = unitPosition.left - unitSVGPosition.left, unitTopPositionInElement = unitPosition.top - unitSVGPosition.top;
            var yCenterOfUnit = unitPosition.height / 2, xCenterOfUnit = unitPosition.width / 2;
            var xPercentage = (unitLeftPositionInElement + xCenterOfUnit) / unitSVGPosition.width * 100;
            var yPercentage = (unitTopPositionInElement + yCenterOfUnit) / unitSVGPosition.height * 100;
            //Create the text element
            var svgTextElement = document.createElement('text');
            svgTextElement.innerHTML = peopleInterestedInUnit;
            svgTextElement.setAttribute('x', xPercentage + '%');
            svgTextElement.setAttribute('y', yPercentage + '%');
            svgTextElement.setAttribute('fill', 'black');
            svgTextElement.style.fontSize = '1.5rem';
            svgTextElement.classList.add('realestate-height-test');
            //Append a test element to get dimensions
            unitSVG.insertAdjacentHTML('beforeend', svgTextElement.outerHTML);
            //Fetch the dimensions of the test element
            var testElement = unitSVG.querySelector('.realestate-height-test'), textElementDimensions = testElement.getBBox();
            //Remove the test element
            testElement.remove();
            //Update the visible element
            svgTextElement.style.visibility = 'visible';
            svgTextElement.setAttribute('transform', 'translate(-' + textElementDimensions.width / 2 + ',' + textElementDimensions.height / 2 + ')');
            svgTextElement.classList.remove('realestate-height-test');
            svgTextElement.classList.add('realestate-height');
            //Create a rect and wrap it around the text
            var svgTextBackground = document.createElement('circle');
            svgTextBackground.classList.add('realestate-height');
            svgTextBackground.setAttribute('cx', 'calc(' + xPercentage + '% - 1px)');
            svgTextBackground.setAttribute('cy', 'calc(' + yPercentage + '% + ' + textElementDimensions.height / 5 + 'px)');
            svgTextBackground.setAttribute('r', '24');
            svgTextBackground.setAttribute('fill', 'white');
            svgTextBackground.style.filter = 'drop-shadow( 0 0 5px rgba(0, 0, 0, .3)';
            //Append the real element
            unitSVG.insertAdjacentHTML('beforeend', svgTextBackground.outerHTML);
            unitSVG.insertAdjacentHTML('beforeend', svgTextElement.outerHTML);
        }
    });
    if (!unitSVG.classList.contains('realestate-unit-click-listener-bound')) {
        (_a = unitSVG.querySelectorAll('[id*="' + situationUnitPrefix + '"]')) === null || _a === void 0 ? void 0 : _a.forEach(function (unitOnSituation) {
            //Add clickable class
            unitOnSituation.classList.add('realestate-clickable-unit');
            //Add a click to the unit
            unitOnSituation.addEventListener('click', function (e) {
                e.preventDefault();
                //Extract the unit number from the id
                var unitID = unitOnSituation.getAttribute('id'), rawUnitNumber = unitID.replace(situationUnitPrefix, ''), unitNumber = null;
                //Check length
                if (rawUnitNumber.length == 1) {
                    unitNumber = rawUnitNumber;
                }
                else if (rawUnitNumber.length == 2) {
                    if (situationUnitUsesLeadingZeroes) {
                        if (parseInt(rawUnitNumber.slice(0, 1)) == 0) {
                            unitNumber = rawUnitNumber.slice(1, 2);
                        }
                        else {
                            unitNumber = rawUnitNumber;
                        }
                    }
                    else {
                        unitNumber = rawUnitNumber;
                    }
                }
                //Open the popup with stats for the user number
                var table = unitSituationContainer.getAttribute('data-table');
                openUnitPopup(table, unitSituationContainer, parseInt(unitNumber), e);
            });
        });
        //Bind the popup close buttons
        var realestatePopupCloseButton_1 = unitSituationContainer.querySelector('.realestate-units-situation-popup-close');
        realestatePopupCloseButton_1 === null || realestatePopupCloseButton_1 === void 0 ? void 0 : realestatePopupCloseButton_1.addEventListener('click', function (e) {
            e.preventDefault();
            realestatePopupCloseButton_1.parentElement.classList.remove('visible');
        });
        unitSVG.classList.add('realestate-unit-click-listener-bound');
    }
};
var createAllStats = function () {
    var chartRows = document.querySelectorAll('.stat-row');
    chartRows && chartRows.forEach(function (chartRow) {
        var statType = chartRow.getAttribute('data-stat');
        var statTable = chartRow.getAttribute('data-table');
        if (statType == 'chart') {
            var rowField_1 = chartRow.getAttribute('data-chart');
            //Fetch the ChartData
            fetchChartData(statTable, rowField_1)
                .then(function (data) {
                //Check if chart data was fetched
                if (data.status === true) {
                    createBasicChart(data, rowField_1, 'pie');
                }
                ;
            });
        }
        else if (statType == 'realestate-units') {
            var reqData = getStatRequestData(statTable), unitSituationContainer_1 = document.querySelector('.realestate-units-situation');
            doRequest(window.cmsurl + '/forms/handlers/realestate/get_unit_pref_chart.php', reqData).then(function (data) {
                createBasicChart(data.units, 'realestate-units', 'bar', false);
                if (unitSituationContainer_1) {
                    fillUnitSituation(unitSituationContainer_1, data.units);
                }
            });
        }
        else if (statType == 'realestate-types') {
            var reqData = getStatRequestData(statTable);
            doRequest(window.cmsurl + '/forms/handlers/realestate/get_unit_pref_chart.php', reqData).then(function (data) {
                createBasicChart(data.types, 'realestate-types', 'pie');
            });
        }
        else if (statType == 'realestate-table') {
            var reqData = getStatRequestData(statTable);
            doRequest(window.cmsurl + '/forms/handlers/realestate/get_realestate_table.php', reqData).then(function (data) {
                chartRow.innerHTML = data.table;
                //Details event listener
                var userFilters = chartRow.querySelectorAll('.realestate-user-filter');
                userFilters === null || userFilters === void 0 ? void 0 : userFilters.forEach(function (userFilter) {
                    userFilter.addEventListener('click', function (e) {
                        e.preventDefault();
                        var clickedUser = userFilter.getAttribute('data-user'), clickedUserName = userFilter.getAttribute('data-username');
                        //Check if we are already filtering by user
                        var existingUserFilter = document.querySelector('form#chart-filter-form input[name="id"]');
                        var existingUserFilterNotice = document.querySelector('form#chart-filter-form .realestate-user-filter-notice');
                        if (existingUserFilter) {
                            if (clickedUser == 'reset') {
                                existingUserFilter.remove();
                                existingUserFilterNotice.remove();
                            }
                            else {
                                //We are already filtering by user, just update the filters
                                existingUserFilterNotice.innerHTML = '<label>Gebruiker:</label> ' + clickedUserName;
                                existingUserFilter.value = clickedUser;
                            }
                            createAllStats();
                        }
                        else {
                            //We are not filtering by user, create the values
                            var userFilter_1 = document.createElement('input');
                            userFilter_1.setAttribute('type', 'hidden');
                            userFilter_1.value = clickedUser;
                            userFilter_1.setAttribute('name', 'id');
                            var userFilterNotice = document.createElement('div');
                            userFilterNotice.classList.add('realestate-user-filter-notice', 'field');
                            userFilterNotice.innerHTML = '<label>Gebruiker:</label> ' + clickedUserName;
                            document.querySelector('form#chart-filter-form .sidebar-fields').append(userFilter_1, userFilterNotice);
                            createAllStats();
                        }
                    });
                });
            });
        }
    });
};
var createEventListeners = function () {
    var filterSelects = document.querySelectorAll('form#chart-filter-form select, form#chart-filter-form input');
    filterSelects && filterSelects.forEach(function (filterSelect) {
        filterSelect.addEventListener('change', function (e) {
            e.preventDefault();
            createAllStats();
        });
    });
};
ready(function (vars) {
    //Loop the chart containers
    createAllStats();
    //Create filter event listeners
    createEventListeners();
});
